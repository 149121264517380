import { FETCH_SIZE } from "./constants";
import { state } from "./State";
import type * as Types from "./types";

export const initialize = (companySkillTags: Types.CompanySkillTag[]): void => {
  state.companySkillTagIds = companySkillTags.map(tag => tag.skillTagId);
  state.initialized = true;
};

export const clear = (): void => {
  state.res = null;
  state.skillTagSet.clear();
  state.companySkillTagIds = [];
  state.initialized = false;
  state.nextOffset = 0;
};

export const updateCompanySkillTagIds = (skillTagIds: Types.SkillTagId[]) => {
  state.companySkillTagIds = skillTagIds;
};

export const updateRes = (res: Types.Res) => {
  state.res = res;
  res.skillTagNodes.forEach(node => {
    state.skillTagSet.add(node.skillTagNodeId);
  });
};

export const clearSkillTagSet = () => {
  state.skillTagSet.clear();
};

export const updateTextFilter = (textFilter: string) => {
  state.textFilter = textFilter;
  /**
   * clear offset when text filter is updated
   */
  clearNextOffset();
};

export const updateNextOffset = () => {
  state.nextOffset += FETCH_SIZE;
};

export const clearNextOffset = () => {
  state.nextOffset = 0;
};
