import { useTranslation } from "@hireroo/i18n";
import Group from "@mui/icons-material/Group";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TagDialog, { TagDialogProps } from "../../../modules/TagDialog/TagDialog";
import OwnerEmailAddressEditor, {
  OwnerEmailAddressEditorProps,
} from "../../../ms-components/Spot/OwnerEmailAddressEditor/OwnerEmailAddressEditor";
import { ButtonWithTooltipProps } from "../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import CopyToClipboardButton, { CopyToClipboardButtonProps } from "../../../primitive/Button/CopyToClipboardButton/CopyToClipboardButton";
import IconButtonWithTooltip from "../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import TextLinkify from "../../../primitive/TextLinkify/TextLinkify";
import AccessPermissionDialog, { AccessPermissionDialogProps } from "../../AccessPermissionDialog/AccessPermissionDialog";
import CustomFormFilledVariable, { CustomFormFilledVariableProps } from "./CustomFormFilledVariable/CustomFormFilledVariable";
import ListContent, { ListContentProps } from "./ListContent";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
  variant: "subtitle2",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "16px",
}));

const StyledModeEditOutlinedIcon = styled(ModeEditOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const WrapTypography = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.secondary.main,
  },
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
}));

export type MemberAnGroupChip = ChipProps & {
  photoUrl?: string;
  kind: "MEMBER" | "GROUP";
};

export type TestSummaryProps = {
  interview: {
    intervieweeName?: string;
    candidateEmail?: string;
    submissionDeadline: string;
    timeLimit: string;
    hasStarted: boolean;
    enabledHint: string;
    employeeName: string;
    invitationLink: string;
    invitationMethod?: string;
    messageForCandidate?: string;
    memo?: string;
    isPublic: boolean;
    tags: string[];
    authorizedMemberAndGroups: MemberAnGroupChip[];
    behavioralControl: string[] | null;
    ownerEmailAddress: string;
  };
  filledVariables: CustomFormFilledVariableProps[];
  copyButton: Pick<CopyToClipboardButtonProps, "onCopy" | "buttonWithTooltip">;
  tagDialog: TagDialogProps;
  accessPermissionDialog: AccessPermissionDialogProps;
  ownerEmailEditor: OwnerEmailAddressEditorProps;
};

const TestSummary: React.FC<TestSummaryProps> = props => {
  const { t } = useTranslation();
  const { interview } = props;
  const copyButton: ButtonWithTooltipProps = {
    ...props.copyButton.buttonWithTooltip,
    size: "small",
    variant: "outlined",
    disabled: interview.hasStarted,
  };

  const editTagButton: ButtonWithTooltipProps = {
    children: <StyledModeEditOutlinedIcon fontSize="small" />,
    onClick: () => {
      props.tagDialog.onOpen?.();
    },
  };
  const editAccessPermissionButton: ButtonWithTooltipProps = {
    children: <StyledModeEditOutlinedIcon fontSize="small" />,
    onClick: () => {
      props.accessPermissionDialog.onOpen?.();
    },
  };
  const basicListContents: ListContentProps[] = [
    {
      title: t("候補者名"),
      children: <StyledTypography>{interview?.intervieweeName ? interview.intervieweeName : t("候補者が開始前です")}</StyledTypography>,
    },
    ...(interview.candidateEmail
      ? [
          {
            title: t("メールアドレス"),
            children: <StyledTypography>{interview?.candidateEmail ? interview.candidateEmail : t("候補者が開始前です")}</StyledTypography>,
          } satisfies ListContentProps,
        ]
      : []),
    {
      title: t("閲覧権限"),
      children: (
        <Box display="flex" alignItems="center">
          {interview?.isPublic ? (
            <StyledTypography>{t("メンバー全員に許可する。")}</StyledTypography>
          ) : (
            <Stack direction={"row"} sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.interview.authorizedMemberAndGroups.map(authorized => {
                const { kind, photoUrl, ...rest } = authorized;
                return <Chip key={authorized.key} {...rest} avatar={kind === "GROUP" ? <Group /> : <Avatar src={photoUrl} />} />;
              })}
            </Stack>
          )}
          <Box flexGrow={1} />
          <IconButtonWithTooltip {...editAccessPermissionButton} />
        </Box>
      ),
    },
    {
      title: t("タグ"),
      children: (
        <Box display="flex">
          {interview?.tags.length > 0 ? (
            <Stack direction={"row"} sx={{ flexWrap: "wrap", gap: "8px" }}>
              {interview?.tags.map(tagName => <StyledChip label={tagName} variant="outlined" key={`tag-chip-${tagName}`} />)}
            </Stack>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <StyledTypography>{t("タグが指定されていません。")}</StyledTypography>
            </Box>
          )}
          <Box flexGrow={1} />
          <IconButtonWithTooltip {...editTagButton} />
        </Box>
      ),
    },
    {
      title: t("提出期限"),
      children: <StyledTypography>{interview.submissionDeadline}</StyledTypography>,
    },
    {
      title: t("カスタムフォーム"),
      children: (
        <Stack direction="column" spacing={1.5}>
          {props.filledVariables.map(filledVariable => (
            <CustomFormFilledVariable key={filledVariable.id} {...filledVariable} />
          ))}
          {props.filledVariables.length === 0 && <StyledTypography>{t("なし")}</StyledTypography>}
        </Stack>
      ),
    },
    {
      title: t("候補者へのメッセージ"),
      children: (
        <StyledTypography sx={{ whiteSpace: "pre-wrap" }}>
          {interview.messageForCandidate ? <TextLinkify>{interview.messageForCandidate}</TextLinkify> : t("候補者へのメッセージはありません。")}
        </StyledTypography>
      ),
    },
    {
      title: t("制限時間"),
      children: <StyledTypography>{interview.timeLimit}</StyledTypography>,
    },
    {
      title: t("候補者に共有する連絡先"),
      children: (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <StyledTypography>{interview.ownerEmailAddress}</StyledTypography>
          <OwnerEmailAddressEditor {...props.ownerEmailEditor} />
        </Stack>
      ),
    },
  ];
  const behavioralControlListContents: ListContentProps[] = interview.behavioralControl
    ? [
        {
          title: t("試験環境で許可された行動"),
          children: (
            <StyledTypography>
              {interview.behavioralControl.length > 0 ? interview.behavioralControl.join(", ") : t("指定なし")}
            </StyledTypography>
          ),
        },
      ]
    : [];
  const hintEnabledListContent: ListContentProps = {
    title: t("ヒント機能"),
    children: <StyledTypography>{interview.enabledHint}</StyledTypography>,
  };
  const invitationListContents: ListContentProps[] = [
    ...(interview.invitationMethod
      ? ([
          {
            title: t("招待者"),
            children: <StyledTypography>{interview.employeeName}</StyledTypography>,
          },
          {
            title: t("招待方法"),
            children: <StyledTypography>{interview.invitationMethod}</StyledTypography>,
          },
          {
            title: t("招待リンク"),
            children: (
              <Box display="flex" alignItems="center">
                <WrapTypography fontSize={14}>{interview.invitationLink}</WrapTypography>
                <Box flexGrow={1} />
                <Box ml={1}>
                  <CopyToClipboardButton
                    name={interview.hasStarted ? t("招待済み") : t("コピー")}
                    url={interview.invitationLink}
                    onCopy={props.copyButton.onCopy}
                    buttonWithTooltip={copyButton}
                  />
                </Box>
              </Box>
            ),
          },
        ] satisfies ListContentProps[])
      : []),
  ];
  const listContents: ListContentProps[] = [
    ...basicListContents,
    ...behavioralControlListContents,
    hintEnabledListContent,
    ...invitationListContents,
  ];

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography fontSize={16} sx={{ fontWeight: "bold" }}>
          {t("テスト情報")}
        </Typography>
      </Box>
      <StyledBox>
        <List component="nav" aria-label="coding-test-summary" sx={{ px: 2 }}>
          {listContents.map((content, index) => (
            <ListContent key={content.title} {...content} divider={index !== listContents.length - 1} />
          ))}
        </List>
      </StyledBox>
      <TagDialog key={`tag-dialog-open-${props.tagDialog.open.toString()}`} {...props.tagDialog} />
      <AccessPermissionDialog
        key={`access-permission-dialog-open-${props.accessPermissionDialog.open.toString()}`}
        {...props.accessPermissionDialog}
      />
    </Box>
  );
};

TestSummary.displayName = "TestSummary";

export default TestSummary;
