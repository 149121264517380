import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import { EntryScreeningForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Trans } from "react-i18next";

import EntryContentPaper from "../../../../modules/EntryContentPaper/EntryContentPaper";
import Link from "../../../../primitive/Link/Link";

const StyledList = styled("ol")(({ theme }) => ({
  paddingTop: 0,
  margin: 0,
  paddingLeft: theme.spacing(2),
  "& li": {
    marginTop: 0,
    marginBottom: "0.75em",
    lineHeight: "24px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

const StyledListItem = styled("li")(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export type OnboardingProps = {
  tosUrl: string;
};

const Onboarding: React.FC<OnboardingProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const methods = useFormContext<EntryScreeningForm.EntryScreeningFormSchema>();

  const items: string[] = [
    t("テストには制限時間があります。一度テストを始めると中断することはできませんので予め余裕を持って始めるようにしてください。"),
    t("問題が複数出題されている場合、問題は順不同で解答することができます"),
    t("制限時間はすべての問題で共通です。時間配分に気をつけて解答するようにしてください"),
    t("制限時間内であれば何度でも提出が可能です。"),
    t(
      "解答の途中で途中でブラウザが閉じてしまった場合は、メールに送信されたテストのリンクからアクセスしてください。その場合でも時間は経過しますのでご注意ください。",
    ),
    t("SNS等に問題文や企業情報等を公開しないでください。"),
    t("本人の代わりにテストを受験するなどの不正行為があった場合、評価に影響する可能性がありますので必ずご本人が解答するようにしてください。"),
  ];
  return (
    <EntryContentPaper>
      <Stack spacing={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "center" }}>
          {t("注意事項")}
        </Typography>
        <Box>
          <Typography variant="body2" gutterBottom>
            {t("この度はお忙しい中テストを受験いただきありがとうございます。")}
          </Typography>
          <Typography variant="body2">{t("テストを開始する前に以下の注意事項をよく読んでから開始してください。")}</Typography>
        </Box>
        <Box>
          <StyledList>
            {items.map((item, index) => (
              <StyledListItem key={index}>
                <React.Suspense>
                  <Markdown size="middle" children={item} />
                </React.Suspense>
              </StyledListItem>
            ))}
          </StyledList>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Controller
            name="agreement"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FormGroup onChange={field.onChange}>
                <Stack direction="column" color="primary">
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={field.value} />}
                    label={
                      <Typography variant="body1" gutterBottom mb={0}>
                        <Trans
                          values={{ termsOfService: `<l>${t("利用規約")}</l>` }}
                          components={{
                            l: <Link href={props.tosUrl} target="_blank" underline="hover" color="secondary" />,
                          }}
                        >
                          {t2("TermsOfServiceAgreement")}
                        </Trans>
                      </Typography>
                    }
                  />
                  {fieldState.error?.message && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                </Stack>
              </FormGroup>
            )}
          />
        </Box>
      </Stack>
    </EntryContentPaper>
  );
};

Onboarding.displayName = "Onboarding";

export default Onboarding;
