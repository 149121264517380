import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Company } from "@hireroo/app-store/essential/employee";
import { SkillMapSettings } from "@hireroo/app-store/widget/e/SkillMapSettings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import SkillTagListFetchContainer from "./widget/SkillTagList/FetchContainer";

export type GenerateSkillMapSettingsPropsArgs = {};

export const useGenerateProps = (_args: GenerateSkillMapSettingsPropsArgs): Widget.SkillMapSettingsProps => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const companyId = Company.useStrictActiveCompanyId();
  const textFilter = SkillMapSettings.useTextFilter();

  const companySkillTagIds = SkillMapSettings.useCompanySkillTagIds();
  const [loading, setLoading] = React.useState(false);

  return {
    SkillTagList: <SkillTagListFetchContainer />,
    textFilter: {
      onSubmit: fields => {
        SkillMapSettings.updateTextFilter(fields.textFilter);
        if (fields.textFilter !== textFilter) {
          SkillMapSettings.clearSkillTagSet();
        }
      },
    },
    skillMapForm: {
      updateButton: {
        disabled: loading,
      },
      onSubmit: fields => {
        setLoading(true);
        client
          .UpdateCompanySkillTagsForSkillMapSettings({
            input: {
              companyId: companyId,
              skillTagIds: fields.skillTags.map(tag => tag.value),
            },
          })
          .then(res => {
            Snackbar.notify({
              message: t("スキルマップを更新しました。"),
              severity: "success",
            });
            SkillMapSettings.updateCompanySkillTagIds(res.updateCompanySkillTags.map(t => t.skillTagId));
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("スキルマップの更新に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    },
    defaultValues: {
      skillTags: companySkillTagIds.map(tag => ({ value: tag })),
    },
  };
};
